import { BatchModifyApprovalStatus } from '@api/types';
import { TimeSheet } from '@stores-models/timesheet';

/**
 * Get all Time Sheets
 *
 * @param filter OData filter
 */
export class GetTimeSheets {
  static readonly type = '[TimeSheet] Get';

  constructor(public filter?: string, public bustCache?: boolean) {}
}

/**
 * Get all Time Sheets
 *
 * @param filter OData filter
 */
export class GetTimeSheet {
  static readonly type = '[TimeSheet] Get One';

  constructor(
    public woNo: string,
    public wolNo: number,
    public lineNo: number
  ) {}
}

/**
 * Get all Work Order Time Sheets
 *
 * @param filter OData filter
 */
export class GetWorkOrderTimeSheets {
  static readonly type = '[TimeSheet] Get Multiple';

  constructor(public workOrderNo?: string) {}
}

/**
 * Delete Time Sheet, this method will dynamically update the cache Time Sheet list and set selected Time Sheet to null.
 *
 * @param no Work Order No
 * @param line Work Order Line No
 * @param tsLine Time Sheet No
 */
export class DeleteTimeSheet {
  static readonly type = '[TimeSheet] Delete';

  constructor(
    public woNo: string,
    public wolNo: number,
    public tsNo: number,
    public optimisticCacheUpdate?: boolean
  ) {}
}

/**
 * Update / Create Time Sheet, this method will dynamically update the cache Time Sheet list and set selected Time Sheet to null.
 *
 * @param payload Time Sheet object
 */
export class UpdateCreateTimeSheets {
  static readonly type = '[TimeSheet] Batch Update';

  constructor(
    public payload: (TimeSheet & { id?: string })[],
    public optimisticCacheUpdate?: boolean
  ) {}
}

export class UpdatePostedTimesheetLinesStatus {
  static readonly type = '[TimeSheet] Update Posted Status Batch';

  constructor(
    public payload: BatchModifyApprovalStatus,
    public optimisticCacheUpdate?: boolean
  ) {}
}

/**
 * Set selected Time Sheet Line
 *
 * @param payload New Time Sheet Line full object. Summary is not supported.
 */
export class SetSelectedTimeSheet {
  static readonly type = '[TimeSheet] Set';

  constructor(public payload: TimeSheet | null) {}
}
