/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateExtendedPipe } from '@helpers/pipes/date-extended.pipe';
import { ApolloService } from '@shared/apollo/apollo.service';
import { PostedTimesheetsGQL } from '@shared/apollo/queries/posted-timesheet';
import { TimesheetsGQL } from '@shared/apollo/queries/timesheet';
import { TimeSheet } from '@stores-models/timesheet';
import { BatchModifyApprovalStatus, BatchWorkOrderLine } from '@api/types';
import { Operation } from 'fast-json-patch';
import type { PostedTimesheet } from '@tag/graphql';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class TimeSheetStoreService {
  constructor(
    private postedTimesheetsGQL: PostedTimesheetsGQL,
    private timesheetsGQL: TimesheetsGQL,
    private dateExtendedPipe: DateExtendedPipe,
    private apollo: Apollo,
    private apolloUtils: ApolloService
  ) {}

  optimisticDataUpdate(tsls: TimeSheet[]) {
    tsls.forEach((ts) => {
      const tsIdFields: (keyof TimeSheet)[] = [
        'sourceWorkOrderNo',
        'sourceWorkOrderLine',
        'timeSheetLineNo',
      ];
      if (ts.posted)
        this.apolloUtils.optimisticCacheUpdate(
          'postedTimesheets',
          tsIdFields,
          ts
        );
      else this.apolloUtils.optimisticCacheUpdate('timesheets', tsIdFields, ts);
    });
  }

  optimisticDataDelete(tsls: TimeSheet[]) {
    tsls.forEach((ts) => {
      const tsIdFields: (keyof TimeSheet)[] = [
        'sourceWorkOrderNo',
        'sourceWorkOrderLine',
        'timeSheetLineNo',
      ];
      if (ts.posted)
        this.apolloUtils.optimisticCacheDelete(
          'postedTimesheets',
          tsIdFields,
          ts
        );
      else this.apolloUtils.optimisticCacheDelete('timesheets', tsIdFields, ts);
    });
  }

  clearCache(includePosted?: boolean) {
    this.apollo.client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'timesheets',
    });
    this.apollo.client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'workOrderLines',
    });

    if (includePosted)
      this.apollo.client.cache.evict({
        id: 'ROOT_QUERY',
        fieldName: 'postedTimesheets',
      });
  }
  /**
   * Fetchs time Sheet
   *
   * @param filter OData filter
   *
   * @returns time Sheet
   */
  fetchTimeSheets(company: string, filter?: string): Observable<TimeSheet[]> {
    const nonPosted = this.timesheetsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((res) =>
          this.mapTimeSheetDtoToLocalTimeSheet(res.data.timesheets.items, false)
        )
      );
    const posted = this.postedTimesheetsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map((res) =>
          this.mapPostedTimeSheetDtoToLocalTimeSheet(
            res.data.postedTimesheets.items
          )
        )
      );
    return combineLatest([nonPosted, posted]).pipe(
      map(([x, y]) => [...x, ...y])
    );
  }

  /**
   * Fetchs time Sheet by work order
   *
   * @param workOrderNo work order number
   *
   * @returns time Sheet
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchWorkOrderTimeSheets(workOrderNo?: string): Observable<TimeSheet[]> {
    const nonPosted = this.timesheetsGQL
      .watch({
        dynamicFilter: {
          sourceWorkOrderNo: workOrderNo,
        },
      })
      .valueChanges.pipe(
        map((res) =>
          this.mapTimeSheetDtoToLocalTimeSheet(res.data.timesheets.items, false)
        )
      );
    const posted = this.postedTimesheetsGQL
      .watch({
        dynamicFilter: {
          originalRoNo: workOrderNo,
        },
      })
      .valueChanges.pipe(
        map((res) =>
          this.mapPostedTimeSheetDtoToLocalTimeSheet(
            res.data.postedTimesheets.items
          )
        )
      );
    return combineLatest([nonPosted, posted]).pipe(
      map(([x, y]) => [...x, ...y])
    );
  }

  /**
   * Fetchs time sheet
   *
   * @param company
   * @param woNo
   * @param wolNo
   * @param lineNo
   * @returns time sheet
   */
  fetchTimeSheet(
    company: string,
    woNo: string,
    wolNo: number,
    lineNo: number
  ): Observable<TimeSheet> {
    const filter = `Source_Work_Order_No eq '${woNo}' and Source_Work_Order_Line eq ${wolNo} and Time_Sheet_Line_No eq ${lineNo}`;
    return this.timesheetsGQL
      .watch({ filter })
      .valueChanges.pipe(
        map(
          (res) =>
            this.mapTimeSheetDtoToLocalTimeSheet(
              res.data.timesheets.items,
              false
            )[0]
        )
      );
  }

  /**
   * Deletes time Sheet
   *
   * @param no
   * @param line
   * @param tsLine
   *
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteTimeSheet(
    no: string,
    line: number,
    tsLine: number,
    company: string
  ): Observable<any> {
    throw new Error('Method not implemented.');
    // return this.timeSheetsService.TimeSheetsSourceWorkOrderNosourceWorkOrderLinetimeSheetLineNoDelete(
    //   company,
    //   no,
    //   line,
    //   tsLine
    // );
  }

  /**
   * Adds time Sheet
   *
   * @param payload
   *
   * @returns time Sheet
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addTimeSheet(payload: TimeSheet, company: string): Observable<TimeSheet> {
    throw new Error('Method not implemented.');
    // return this.timeSheetsService
    //   .TimeSheetsPost(company, payload)
    //   .pipe(
    //     map((res) => this.mapTimeSheetDtoToLocalTimeSheet([res], false)[0])
    //   );
  }

  /**
   * Adds time Sheets
   *
   * @param payload
   *
   * @returns time Sheet
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addTimeSheets(
    payload: BatchWorkOrderLine,
    company: string
  ): Observable<BatchWorkOrderLine> {
    throw new Error('Method not implemented.');
    // return this.workOrderLinesService.WorkOrdersDocumentTypeLinesBatchPost(
    //   company,
    //   WorkOrderDocumentType.Released,
    //   payload
    // );
  }

  /**
   * Updates time Sheet
   *
   * @param no
   * @param patch
   *
   * @returns time Sheet
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateTimeSheet(
    no: string,
    line: number,
    tsLine: number,
    patch: Operation[],
    company: string
  ): Observable<TimeSheet> {
    throw new Error('Method not implemented.');
    // return this.timeSheetsService
    //   .TimeSheetsSourceWorkOrderNosourceWorkOrderLinetimeSheetLineNoPatch(
    //     company,
    //     no,
    //     line,
    //     tsLine,
    //     patch
    //   )
    //   .pipe(
    //     map((res) => this.mapTimeSheetDtoToLocalTimeSheet([res], false)[0])
    //   );
  }

  /**
   * Updates create time sheets
   *
   * @param payload
   * @param company
   * @returns TimeSheet List
   */
  updateCreateTimeSheets(
    payload: TimeSheet[],
    company: string
  ): Observable<TimeSheet[]> {
    const body = {
      time_Sheet_Lines: payload,
    };

    throw new Error('Method not implemented.');

    // return this.timeSheetLinesService
    //   .TimeSheetLinesLinesPost(company, body)
    //   .pipe(
    //     map((res) => {
    //       const tsls: TimeSheet[] = [];
    //       res.time_Sheet_Lines?.forEach((tsl) => {
    //         const x = this.mapTimeSheetDtoToLocalTimeSheet([tsl], !!tsl.post);
    //         if (x[0]) {
    //           x[0].created_By = tsl.web_Service_User;
    //           tsls.push(x[0]);
    //         }
    //       });

    //       (res as any).finished_Work_Order_Lines?.forEach((fwol: any) => {
    //         const x = this.mapTimeSheetDtoToLocalTimeSheet([fwol], !!fwol.post);
    //         if (x[0]) {
    //           x[0].created_By = fwol.web_Service_User;
    //           tsls.push(x[0]);
    //         }
    //       });

    //       return tsls;
    //     })
    //   );
  }

  /**
   * Updates finished work order lines status
   *
   * @param payload
   * @param company
   * @returns finished work order lines status
   */
  updateFinishedWorkOrderLinesStatus(
    payload: BatchModifyApprovalStatus,
    company: string
  ): Observable<BatchModifyApprovalStatus> {
    throw new Error('Method not implemented.');
    // return this.finishedWorkOrderLinesService.FinishedWorkOrdersBatchApprovalStatusPost(
    //   company,
    //   payload
    // );
  }

  /**
   * Maps time sheet dto to local time sheet
   *
   * @param ts
   * @returns time sheet
   */
  mapTimeSheetDtoToLocalTimeSheet(
    ts: (TimeSheet | any)[],
    posted: boolean
  ): TimeSheet[] {
    return ts
      .filter((t) => {
        const start = this.dateExtendedPipe.transform(t.starting_Datetime);
        const end = this.dateExtendedPipe.transform(t.ending_Datetime);
        if (
          !start ||
          !end ||
          t.time_Sheet_Line_No === undefined ||
          !t.source_Work_Order_No ||
          t.source_Work_Order_Line === undefined
        ) {
          console.warn('Invalid time sheet line', t);
          return false;
        }
        return true;
      })
      .map((t) => ({
        ...t,
        id: `${t.source_Work_Order_No}-${t.source_Work_Order_Line}-${t.time_Sheet_Line_No}`,
        starting_Datetime: new Date(t.starting_Datetime ?? ''),
        ending_Datetime: new Date(t.ending_Datetime ?? ''),
        time_Sheet_Line_No: t.time_Sheet_Line_No ?? 0,
        source_Work_Order_No: t.source_Work_Order_No ?? '',
        source_Work_Order_Line: t.source_Work_Order_Line ?? 0,
        // finishedWorkOrder: (t as TimesheetLine).finished_Work_Order_No,
        // finishedLineNos: (t as TimesheetLine).finished_Work_Order_Line_No,
        posted,
      }));
  }

  /**
   * Maps posted time sheet dto to local time sheet
   *
   * @param ts
   * @returns posted time sheet dto to local time sheet
   */
  mapPostedTimeSheetDtoToLocalTimeSheet(ts: PostedTimesheet[]): TimeSheet[] {
    return ts
      .filter((t) => {
        const start = this.dateExtendedPipe.transform(t.startingDatetime);
        const end = this.dateExtendedPipe.transform(t.endingDatetime);
        if (!start || !end || !t.workOrderNo || t.lineNo === undefined) {
          console.warn('Invalid time sheet line', t);
          return false;
        }
        return true;
      })
      .map(
        (t) =>
          ({
            ...t,
            id: `${t.workOrderNo}-${t.lineNo}`,
            startingDatetime: new Date(t.startingDatetime ?? ''),
            endingDatetime: new Date(t.endingDatetime ?? ''),
            timeSheetLine_No: 0,
            sourceWorkOrderNo: t.originalRoNo ?? '',
            sourceWorkOrderLine: t.originalRoLine ?? 0,
            finishedWorkOrder: t.workOrderNo ?? '',
            finishedLineNo: t.lineNo ?? 0,
            posted: true,
          } as any)
      );
  }
}
